import { ref } from 'vue'
import axios from 'axios'
import useSnackbar, { SnackbarType } from '@/composables/snackbar'

export default function useExercises() {
  const RESOURCE = '/exercise'

  const { showSnackbar } = useSnackbar()

  const dtHeaders = [
    {
      text: 'UNIQUE KEY', value: 'uniqueKey',
    },
    {
      text: 'THUMBNAIL MALE', value: 'thumbnail', sortable: false, width: 130,
    },
    {
      text: 'THUMBNAIL FEMALE', value: 'thumbnailFemale', sortable: false, width: 130,
    },
    {
      text: 'NAME', value: 'name',
    },
    {
      text: 'TAGS', value: 'tags',
    },
    {
      text: 'SEARCH TAGS', value: 'searchTags', width: 80,
    },
    {
      text: 'VIDEO', value: 'video', sortable: false,
    },
    {
      text: 'TIPS', value: 'tips',
    },
    {
      text: 'TIME', value: 'time', width: 100,
    },
    {
      text: 'COUNTDOWN TIME', value: 'countdownTime', width: 100,
    },
    {
      text: 'ACTIONS', value: 'actions', sortable: false, width: 100, align: 'center',
    },
  ]
  const dtSearchQuery = ref('')
  const dtExpanded = ref([])

  const blankExercise = {
    id: '',
    uniqueKey: '',
    category: '',
    name: '',
    tags: [],
    searchTags: [],
    time: 0,
    thumbnail: '',
    thumbnailFemale: '',
    video: {
      url: '',
    },
    videoFemale: {
      url: '',
    },
    instruction: {
      imageUrl: '',
      imageUrlFemale: '',
      description: '',
    },
    tips: [],
    countdownTime: 0,
    details: {
      instructions: {
        title: '',
        images: [],
      },
    },
  }

  const exercises = ref([])
  const exercise = ref(structuredClone(blankExercise))

  const isLoading = ref(false)

  const createExercise = async exerciseItem => {
    isLoading.value = true

    await axios.post(RESOURCE, exerciseItem)
      .then(() => {
        showSnackbar('You have successfully created a new exercise', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to create a new exercise:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteExercise = async exerciseItem => {
    isLoading.value = true
    exerciseItem.deleted = true

    await axios.put(RESOURCE, exerciseItem)
      .then(() => {
        showSnackbar('You have successfully deleted the exercise', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to delete the exercise:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const fetchExercises = async (category = null) => {
    isLoading.value = true

    await axios
      .get(RESOURCE)
      .then(response => {
        const filteredData = category ? response.data.filter(exercise => exercise.category === category) : response.data

        exercises.value = filteredData
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to fetch exercises:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const updateExercise = async exerciseItem => {
    isLoading.value = true

    await axios.put(RESOURCE, exerciseItem)
      .then(() => {
        showSnackbar('You have successfully updated the exercise', SnackbarType.SUCCESS)
      })
      .catch(error => {
        const message = error.response?.data?.message ?? error.message
        const statusCode = error.response?.status ?? null

        showSnackbar(
          `Failed to update the exercise:<br> ${statusCode ? `[${statusCode}]` : ''} ${message}`,
          SnackbarType.ERROR,
        )

        throw error
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  return {
    dtHeaders,
    dtSearchQuery,
    dtExpanded,

    blankExercise,
    exercises,
    exercise,

    isLoading,

    createExercise,
    deleteExercise,
    fetchExercises,
    updateExercise,
  }
}
